  input[type=text] {
  border-radius: 5px;
  font-family: "IBM Plex Mono", "Consolas", "Courier New", Courier, monospace;
  font-size: 14px;
  height: 2rem;
}

.close {
  align-self: start;
  background-color:var(--main-background);
  border: none;
  color: var(--main-font);
  cursor: pointer;
  font-size: 24px;
  justify-self: end;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

#profile-edit .field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3rem;
}

#profile-edit .form {
  width: 100%;
  width: 780px;
}

.ftue .form {
  margin-top: 8rem;
}

#profile-edit .form label {
  width: 600px;
  font-size: 1.4em;
}

#profile-edit .form label {
  font-size: 1.6em;
}


.grid {
  display: grid;
  grid-column-gap: 5%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: repeat(3, 1fr)
} 

#profile-edit .submit {
  float: right;
}

#profile-edit h1 {
    margin-top: 0;
}


@media only screen and (max-width: 600px) {
  #profile-edit .field {
    margin-bottom: 2rem;
  }

  .ftue .form {
    margin-top: 1rem;
  }

  #profile-edit em {
    line-height: 2em;
  }
}
