#messages {
  height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.message-wrapper .time {
  margin-right: 1.5em;
  color: var(--gray-text);
  float: left;
  font-size: 0.8em;
  text-transform: lowercase;
}

.message-wrapper .time.show-on-hover {
  opacity: 0;
}

.message-wrapper:hover .time.show-on-hover {
  opacity: 1
}

.message-wrapper.even-message {
  background-color: var(--alternate-background)
}

.message {
  overflow-wrap: break-word;
}

.movement-message {
  opacity: 0.5
}