@import "./fonts.css";
@import "./modal.css";

/* Default theme */
:root {
  --main-background: #212121;
  --main-font: #eee;
  --gray-text: #b5b5b5;
  --alternate-background: #333333;
  --alternate-translucent: rgba(51,51,51,0.8);
  --green: #20b2aa;
  --highlight-line: #666666;
  --mod-line: #2f2f2f;
  --pink: lightpink;
  --blue: #87ceeb;
  --cyan: #00ffff;
  --magenta: #ff00ff;
  --orange: #ffa500;
  --red: #ff0000;
  --violet: #ee82ee;
  --yellow: #ffff00;
}

/* solarized dark */
.solarized-dark{
  --main-background:#002b36;
  --main-font:#657b83;
  --gray-text:#586e75;
  --alternate-background: #073642;
  --alternate-translucent: rgba(7,54,66,0.8);
  --green: #859900;
  --highlight-line: #fdf6e3;
  --mod-line: #073642;
  --pink: #d33682;
  --blue: #268bd2;
  --cyan: #2aa198;
  --magenta: #d33682;
  --orange: #cb4b16;
  --red: #dc322f;
  --violet: #6c71c4;
  --yellow: #b58900;
}

/* solarized light */
.solarized-light{
  --main-background:#fdf6e3;
  --main-font:#657b83;
  --gray-text:#93a1a1;
  --alternate-background: #eee8d5;
  --alternate-translucent: rgba(238,232,213,0.8);
  --green:#859900;
  --highlight-line: #002b36;
  --mod-line: #eee8d5;
  --pink: #d33682;
  --blue: #268bd2;
  --cyan: #2aa198;
  --magenta: #d33682;
  --orange: #cb4b16;
  --red: #dc322f;
  --violet: #6c71c4;
  --yellow: #b58900;
}

body {
  background-color: var(--main-background);
  color: var(--main-font);
  font-family: "IBM Plex Mono", "Consolas", "Courier New", Courier, monospace;
}

#app {
  padding: 0 3%;
  display: grid;
  grid-template-columns: 250px 5fr 1fr;
  grid-gap: 3%;
}

@media only screen and (max-device-width: 500px) {
  #app {
    padding: 5px;
    box-sizing: border-box;
  }
}

#app-profile-open {
  padding: 0 3%;
  display: grid;
  grid-template-columns: 250px 5fr 400px;
  grid-gap: 3%;
}

@media only screen and (max-device-width: 500px) {
  #app {
    grid-template-columns: auto;
  }
}

#main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  color:var(--green);
  text-decoration: none;
  font-weight: bold;
}

.whisper {
  color: var(--gray-text);
  cursor: pointer;
  font-style: italic;
}

.error {
  color: var(--pink);
  font-style: italic;
}

a:visited {
  color: var(--green);
}

.link-styled-button {
  background-color: transparent;
  border: none;
  color: var(--green);
  cursor: pointer;
  display: inline; 
  font-family: "IBM Plex Mono", "Consolas", "Courier New", Courier, monospace;
  font-size: 1.0em;
  margin: 0;
  padding: 0;
  text-align: left;
}

button {
  background-color:var(--main-background);
  border: 2px solid var(--highlight-line);
  border-radius: 5px;
  color: var(--main-font);
  cursor: pointer;
  font-family: "IBM Plex Mono", "Consolas", "Courier New", Courier, monospace;
  font-size: 16px;
  padding: 0.5rem 1rem;
}

input[type=radio] {
  vertical-align: baseline;
  margin-right: 1em;
  max-width: 20px;
}

#settingsContainer div {
  display: block
}

.settingsContainer .radio {
  margin-left: 1em;
}

#movementNotificationForm input[type=checkbox] {
  margin: 1em;
  max-width: 20px;
}

#movementNotificationForm input[type=number] {
  margin: 1em;
  max-width: 40px;
}

td {
  padding-right: 1em;
}

#code-of-conduct li {
  margin-bottom: 1.5em;
  padding-left: 0.5em;
}

#code-of-conduct ul {
  list-style-type: circle;
  padding-left: 2em;
}

.form-header {
  font-size: 1.3em;
  display: block;
  margin-bottom: 0.5em;
}

.settingsContainer .form {
  margin-bottom: 1em;
}