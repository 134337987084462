.note-wall-description {
    margin-bottom: 2em;
}

.note {
    display: inline-block;
    max-width: 20rem;
    margin: 30px;
    vertical-align: top;
    word-wrap: break-word;
}

button.note-delete {
    color: var(--pink);
    float: right;
    margin-left: 10px;
}

.like-button {
    color: var(--blue);
    float: right;
    padding-left: 20px;
    padding-right: 15px;
}

.like-button svg {
    vertical-align: top !important;
    margin-left: 4px;
}
