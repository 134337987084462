
#profile .fixed {
  position: fixed;
  overflow-y: auto;
  height: 100%;
}

@media only screen and (max-device-width: 500px) {
  #profile {
    background-color:var(--main-background);
    font-size: 40px;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
  }

  #profile input {
    width: 30px;
  }
}

#profile .close-profile {
  height: 2rem;
}

#profile h1 {
  margin-top: 0;
}

#profile {
  background-color: var(--main-background);
  border-left: 1px solid var(--main-font);
  box-sizing: border-box;
  height: 100%;
  padding: 0px 0px 20px 20px;
  width: 100%;
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
}

#profile-close {
  background-color: var(--main-background);
  border: none;
  color: var(--main-font);
  cursor: pointer;
  font-size: 24px;
  width: 30px;
}

#chat-container {
  border-top: 1px dashed var(--main-font);
  margin-top: 20px;
}

#chat-header {
  background-color: var(--main-background);
  padding: 20px 0px;
  top: 0;
  width: 100%;
}

#chat {
  overflow-y: auto;
  overflow-x: hidden;
  height: 40vh;
}

#input {
  display: flex;
}

input {
  box-sizing: border-box;
  width: 100%;
}
